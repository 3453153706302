






















































































































































































































































































































































































































import Vue from "vue";
import { AuthSignup } from "@/interfaces/auth/auth_signup";
import { MapAutoCompleteResult } from "@/interfaces/onboarding/onboarding";
import {
  cities,
  get_password_validator_regex,
  parseStrToInt
} from "@/utils/global";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { email } from "vuelidate/lib/validators";
import { GET_USER_DETAILS, SIGNUP_USER } from "@/store/modules/auth/constants";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import SignupHeader from "@/views/auth/SignupHeader.vue";

export default Vue.extend({
  name: "InceptionSignupSteps",
  components: { SignupHeader },
  data() {
    return {
      step: 1,
      loading: false,
      valid: false,
      validate_step6: false,
      source_link:
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_1.webm",
      text_question: this.$t("candidate-onboarding.form.full-name").toString(),
      payload: {
        valid_form: false,
        signup_form: {
          name: "",
          mobile_number: "",
          eid_number: "",
          al_ain_user: null,
          address: "",
          education: "",
          program: "",
          availability: "true",
          laptop: "true",
          accommodations: "true",
          wifi_availability: "true",
          email: "",
          password: "",
          nationality: null,
          is_employed: "true",
          city: this.$t("auth.al-ain-city").toString(),
          is_student: "true",
          university_name: ""
        },
        show_password: false,
        education: [
          this.$t("auth.high-school").toString(),
          this.$t("auth.bachelors").toString(),
          this.$t("auth.masters").toString(),
          this.$t("auth.phd").toString()
        ],
        program: [
          this.$t("auth.linkedin").toString(),
          this.$t("auth.mawahed").toString(),
          this.$t("auth.instagram").toString(),
          this.$t("auth.word_of_mouth").toString(),
          this.$t("auth.others").toString()
        ],
        cities: cities
        // nationality: [this.$t("auth.uae").toString()]
      } as AuthSignup,
      mobileNumberError: ""
    };
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("auth", {
      get_user_detail: GET_USER_DETAILS
    })
  },
  created() {
    if (this.get_site_direction === SiteDirections.RTL)
      this.source_link =
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_1.webm";
    else
      this.source_link =
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_1.webm";
  },
  watch: {
    get_site_direction() {
      // Set the source link based on the site direction
      this.text_question = this.$t(this.get_step_value()).toString();
      // Set the payload values based on the site direction
      const selected_index = this.payload.education.indexOf(
        this.payload.signup_form.education
      );
      // const selected_city = this.payload.cities?.indexOf(
      //   this.payload?.signup_form.city
      // );
      this.payload.education = [
        this.$t("auth.high-school").toString(),
        this.$t("auth.bachelors").toString(),
        this.$t("auth.masters").toString(),
        this.$t("auth.phd").toString()
      ];
      this.payload.program = [
        this.$t("auth.linkedin").toString(),
        this.$t("auth.mawahed").toString(),
        this.$t("auth.word_of_mouth").toString()
      ];
      // this.payload.cities = [
      //   this.$t("auth.al-ain-city").toString(),
      //   this.$t("auth.abu-dhabi").toString(),
      //   this.$t("auth.dubai").toString(),
      //   this.$t("auth.sharjah").toString(),
      //   this.$t("auth.ajman").toString(),
      //   this.$t("auth.fujairah").toString(),
      //   this.$t("auth.ras-al-khaimah").toString(),
      //   this.$t("auth.um-al-quwain").toString()
      // ];

      // this.payload.signup_form.city = this.payload.cities[selected_city];
      this.payload.signup_form.education =
        this.payload.education[selected_index];
      this.payload.signup_form.program = this.payload.program[selected_index];
    }
  },
  methods: {
    email,
    get_password_validator_regex,
    parseStrToInt,
    ...mapActions("auth", {
      signup_user: SIGNUP_USER
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    on_next() {
      if (this.step === 14 && this.payload.signup_form.is_student !== "true") {
        this.step++;
      }
      let form = `form${this.step + 1}`;
      if (this.$refs?.[form]) {
        (this.$refs[form] as any).resetValidation(); // Reset validation before revalidating
      }
      // Checking the current Step and validating the form & setting the sourcelink & text based on the step
      if (this.step === 1) {
        if ((this.$refs?.form1 as any)?.validate()) {
          this.step++;
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_2.webm",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_2.webm"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.mobile-number"
          ).toString();
        }
      } else if (this.step === 2) {
        if (
          (this.$refs?.form2 as any)?.validate() &&
          this.mobileNumberError === ""
        ) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_3.webm",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_3.webm"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.emirati"
          ).toString();
          this.step++;
        }
      } else if (this.step === 3) {
        if ((this.$refs?.form3 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_4.webm",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_4.webm"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.eid-number"
          ).toString();
          this.step++;
        }
      } else if (this.step === 4) {
        if ((this.$refs?.form4 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_5.webm",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_5.webm"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.al-ain"
          ).toString();
          this.step++;
        }
      } else if (this.step === 5) {
        if ((this.$refs?.form5 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_6.webm",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_6.webm"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.address"
          ).toString();
          this.step++;
        }
      } else if (this.step === 6) {
        if (this.payload.signup_form.address === "") {
          this.validate_step6 = true;
        } else {
          this.validate_step6 = false;
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_7.webm",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_7.webm"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.education"
          ).toString();
          this.step++;
        }
      } else if (this.step === 7) {
        if ((this.$refs?.form7 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Qudra1-V2-english-updated.mp4",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/R-1-arabic-updated.mp4"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.program"
          ).toString();
          this.step++;
        }
      } else if (this.step === 8) {
        if ((this.$refs?.form8 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Qudra3-V2-english-updated.mp4",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/R-3-arabic-updated.mp4"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.availability"
          ).toString();
          this.step++;
        }
      } else if (this.step === 9) {
        if ((this.$refs?.form9 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Qudra2-V2-english-updated.mp4",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/R-2-arabic-updated.mp4"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.laptop"
          ).toString();
          this.step++;
        }
      } else if (this.step === 10) {
        if ((this.$refs?.form10 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Qudra4-V2-english-updated.mp4",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/R-4-arabic-updated.mp4"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.accommodations"
          ).toString();
          this.step++;
        }
      } else if (this.step === 11) {
        if ((this.$refs?.form11 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/eng-ques-wifi.mp4",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/eng-ques-wifi-arabic.mp4"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.wifi-availability"
          ).toString();
          this.step++;
        }
      } else if (this.step === 12) {
        if ((this.$refs?.form12 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Video-eng-employed.mp4",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Video-ar-employed.mp4"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.is-employed"
          ).toString();
          this.step++;
        }
      } else if (this.step === 13) {
        if ((this.$refs?.form13 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Video-student-01.mp4",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Arabic-student-1.mp4"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.is-student"
          ).toString();
          this.step++;
        }
      } else if (this.step === 14) {
        if ((this.$refs?.form14 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Video-student-02.mp4",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Arabic-student-2.mp4"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.university-name"
          ).toString();
          this.step++;
        }
      } else if (this.step === 15) {
        if (
          this.payload.signup_form.is_student !== "true" ||
          (this.$refs?.form15 as any)?.validate()
        ) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_8.webm",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_8.webm"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.email"
          ).toString();
          this.step++;
        }
      } else if (this.step === 16) {
        if ((this.$refs?.form16 as any)?.validate()) {
          this.set_source_by_direction(
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_9.webm",
            "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_9.webm"
          );
          this.text_question = this.$t(
            "candidate-onboarding.form.password"
          ).toString();
          this.step++;
        }
      } else if (this.step === 17) {
        if ((this.$refs?.form17 as any)?.validate()) {
          this.loading = true;
          // Submit Form to Sign-up the user & to verify email
          this.submit_form();
        }
      }
    },
    async on_back() {
      if (this.step === 1) {
        await this.$router.push("/auth");
        return;
      }
      if (this.step === 16 && this.payload.signup_form.is_student !== "true") {
        this.step--;
      }
      // Handle Source link & text-message based on the step and language
      if (this.step > 1) {
        switch (this.step) {
          case 17:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_8.webm",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_8.webm"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.email"
            ).toString();
            break;
          case 16:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Video-student-02.mp4",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Arabic-student-2.mp4"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.university-name"
            ).toString();
            break;
          case 15:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Video-student-01.mp4",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Arabic-student-1.mp4"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.is-student"
            ).toString();
            break;
          case 14:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Video-eng-employed.mp4",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Video-ar-employed.mp4"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.is-employed"
            ).toString();
            break;
          case 13:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/eng-ques-wifi.mp4",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/eng-ques-wifi-arabic.mp4"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.wifi-availability"
            ).toString();
            break;
          case 12:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Qudra4-V2-english-updated.mp4",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/R-4-arabic-updated.mp4"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.accommodations"
            ).toString();
            break;
          case 11:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Qudra2-V2-english-updated.mp4",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/R-2-arabic-updated.mp4"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.laptop"
            ).toString();
            break;
          case 10:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Qudra3-V2-english-updated.mp4",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/R-3-arabic-updated.mp4"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.availability"
            ).toString();
            break;
          case 9:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Qudra1-V2-english-updated.mp4",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/R-1-arabic-updated.mp4"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.program"
            ).toString();
            break;
          case 8:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_7.webm",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_7.webm"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.education"
            ).toString();
            break;
          case 7:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_6.webm",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_6.webm"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.address"
            ).toString();
            break;
          case 6:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_5.webm",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_5.webm"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.al-ain"
            ).toString();
            break;
          case 5:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_4.webm",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_4.webm"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.eid-number"
            ).toString();
            break;
          case 4:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_3.webm",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_3.webm"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.emirati"
            ).toString();
            break;
          case 3:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_2.webm",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_2.webm"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.mobile-number"
            ).toString();
            break;
          case 2:
            this.set_source_by_direction(
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_1.webm",
              "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_1.webm"
            );
            this.text_question = this.$t(
              "candidate-onboarding.form.full-name"
            ).toString();
            break;
        }
        this.step--;
      }
    },
    get_step_value(): string {
      // base on the steps return locale value text
      switch (this.step) {
        case 1:
          return "candidate-onboarding.form.full-name";
        case 2:
          return "candidate-onboarding.form.mobile-number";
        case 3:
          return "candidate-onboarding.form.emirati";
        case 4:
          return "candidate-onboarding.form.eid-number";
        case 5:
          return "candidate-onboarding.form.al-ain";
        case 6:
          return "candidate-onboarding.form.address";
        case 7:
          return "candidate-onboarding.form.education";
        case 8:
          return "candidate-onboarding.form.program";
        case 9:
          return "candidate-onboarding.form.availability";
        case 10:
          return "candidate-onboarding.form.laptop";
        case 11:
          return "candidate-onboarding.form.accommodations";
        case 12:
          return "candidate-onboarding.form.wifi-availability";
        case 13:
          return "candidate-onboarding.form.is-employed";
        case 14:
          return "candidate-onboarding.form.is-student";
        case 15:
          return "candidate-onboarding.form.university-name";
        case 16:
          return "candidate-onboarding.form.email";
        case 17:
          return "candidate-onboarding.form.password";
        default:
          return "";
      }
    },
    set_source_by_direction(ltr_source: string, rtl_source: string) {
      // Set the source link based on the site direction
      if (this.get_site_direction === SiteDirections.LTR)
        this.source_link = ltr_source;
      else this.source_link = rtl_source;
    },
    input_place(place: MapAutoCompleteResult) {
      // place input by gmap autocomplete
      this.payload.signup_form.address = place.formatted_address;
    },
    async submit_form() {
      // Submit Form to Sign-up the user & to verify email
      const split_name = this.payload.signup_form.name.split(" ");
      const first_name = split_name[0].trim();
      let last_name: string | null = null;
      if (split_name.length > 1) {
        last_name = split_name.slice(1).join(" ").trim();
      }
      const obj: any = {
        first_name: first_name,
        last_name: last_name,
        mobile_number: this.payload.signup_form.mobile_number,
        email: this.payload.signup_form.email,
        education: this.payload.signup_form.education,
        hear_from: this.payload.signup_form.program,
        is_full_time_available:
          this.payload.signup_form.availability === "true" ? true : false,
        is_laptop_available:
          this.payload.signup_form.laptop === "true" ? true : false,
        accommodation_required:
          this.payload.signup_form.accommodations === "true" ? true : false,
        password: this.payload.signup_form.password,
        al_ain_user: this.payload.signup_form.city === "Al Ain" ? true : false,

        address: this.payload.signup_form.address,
        nationality:
          this.payload.signup_form.nationality === "true"
            ? "United Arab Emirates"
            : "Foreigner",
        is_wifi_available:
          this.payload.signup_form.wifi_availability === "true" ? true : false,
        city: this.payload.signup_form.city,
        is_employed:
          this.payload.signup_form.is_employed === "true" ? true : false,
        is_student:
          this.payload.signup_form.is_student === "true" ? true : false,
        university_name: this.payload.signup_form.university_name
      };
      if (this.payload.signup_form.eid_number)
        obj["eid_number"] = this.payload.signup_form.eid_number;
      const result = await this.signup_user(obj);
      if (result) {
        await this.$router.push({
          name: "verify-email"
        });
      }
      this.loading = false;
    },
    checkNumber() {
      let mobile_number = this.payload.signup_form.mobile_number;
      let number = Number(mobile_number);

      if (!Number.isFinite(number) || number <= 0) {
        console.log("error");
        this.mobileNumberError = this.$t("rules.mobile_number").toString(); // ✅ Convert to string
      } else {
        this.mobileNumberError = ""; // Clear error if valid
      }
    }
  }
});
